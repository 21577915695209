<template>
    <div class="container">
        <div class="row align-items-center justify-content-center" style="min-height: 100vh">
            <div class="col-auto text-center">
                <img class="icon mb-3" src="../assets/images/safety.png">

                <div class="mb-5">
                    ดำเนินการปกป้องบัญชีของท่าน <br> ด้วยการยืนยันตัวตนแบบ 2 ขั้นตอน
                </div>

                <div v-if="isInit">
                    <div class="mb-3 w-100 btn btn-primary no-radius btn-lg" @click="getOTP" v-if="!isLoading">
                        ENABLE
                    </div>

                    <div class="mb-3 w-100 btn btn-primary no-radius btn-lg" v-if="isLoading">
                        <div class="row justify-content-center">
                            <div class="col-auto">
                                <div class="loader"></div>
                            </div>
                            <div class="col-auto px-0">LOADING</div>
                        </div>
                    </div>
                </div>

                <message-dialog :data="messageDialog"/>
                <call-dialog/>

            </div>
        </div>

    </div>
</template>

<script>

    import {
        GET_OTP, SET_USER_INFO, CHECK_PHONE
    } from "../store/actions.type";
    import store from '../store';
    import MessageDialog from "../components/Message";
    import {mapState} from 'vuex';
    import CallDialog from "../components/Call";

    export default {
        name: 'Home',
        components: {CallDialog, MessageDialog},
        computed: {
            ...mapState({
                phone: state => state.otp.phone,
            }),
        },
        data() {
            return {
                form: {},
                isLoading: false,
                isInit: false,

                skipMode: false,
                messageDialog: {}
            }
        },
        async created() {
            let {uToken, userId, deviceId, deviceName} = this.$route.query;

            this.form = {
                "uToken": uToken || null,
                "userId": userId || null,
                "deviceId": deviceId || null,
                "deviceName": deviceName || null
            };

            await Promise.all([
                store.dispatch(SET_USER_INFO, {
                    "uToken": this.form.uToken,
                    "userId": this.form.userId,
                    "deviceId": this.form.deviceId,
                    "deviceName": this.form.deviceName,
                }),
                store.dispatch(CHECK_PHONE, {
                    "uToken": this.form.uToken,
                    "userId": this.form.userId
                })
            ]);


            this.isInit = true;
        },
        methods: {
            skip() {
                window.location.href = "https://mket-2fa.stockradars.co/skip2fa";
            },
            async getOTP() {

                if (!this.phone) {
                    return this.$bvModal.show('modal-call');
                }


                if (this.isLoading) return;

                this.isLoading = true;
                let response = await store.dispatch(GET_OTP, this.form);
                this.isLoading = false;


                if (response.data) {
                    await this.$router.push('/verify');
                } else {
                    if (response?.messageCode === "This device already registered") {
                        return await this.$router.push('/error?type=already_register');
                    }


                    this.messageDialog = {
                        type: "error",
                        title: `<div class="mb-3">
                        Fail to verify
                      </div>`,
                        message: `<div class="text-secondary">
                                    You’ve entered a wrong <br>
                                    verification code Please try again.
                                </div>
                        </div>`
                    };

                    this.$bvModal.show('modal-message');
                }


            }
        }
    }
</script>


<style lang="scss" scoped>
    .icon {
        max-height: 100px;
    }
</style>
